import React from 'react';

export default InputComponent => ({
  input,
  meta: { touched, error },
  ...rest
}) => {
  const hasError = !!(touched && error);
  return <InputComponent {...input} hasError={hasError} {...rest} />;
};
