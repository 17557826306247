import styled, { css } from 'styled-components/macro';
import { console0, stiffy1, stiffy } from '../../styles/colors';

const ErrorStyle = css`
  box-shadow: 0px 2px 0px orange;
  border-bottom: 2px solid orange;
`;

export default styled.input`
  width: 100%;
  outline: none;
  height: 4.6rem;
  cursor: default;
  box-shadow: none;
  line-height: 1.6;
  font-size: 1.5rem;
  color: ${console0};
  border-radius: 0px;
  transition: all 0.3s;
  font-family: inherit;
  padding: 1rem 1.5rem;
  background-color: ${stiffy1};
  box-shadow: 0px 2px 0px ${stiffy};
  border-bottom: 2px solid ${stiffy};
  font-family: 'Work Sans', sans-serif;

  ${({ hasError }) => hasError && ErrorStyle}
`;
