import styled from 'styled-components/macro';
import { console0, dinosaur0, dinosaur1 } from '../../styles/colors';

export default styled.button`
  border: none;
  font-size: 1.5rem;
  color: ${console0};
  position: relative;
  text-align: center;
  padding: 1.5rem 4rem;
  transition: all 0.2s;
  text-decoration: none;
  display: inline-block;
  background-color: ${dinosaur0};
  border-bottom: 2px solid ${dinosaur1};
  font-family: 'Work Sans', sans-serif;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
`;
