import React from 'react';
import PropTypes from 'prop-types';
import { TextAreaField } from './styles';

const TextArea = props => <TextAreaField {...props} />;

TextArea.defaultProps = {
  disabled: false,
  hasError: false
};

TextArea.propTypes = {
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default TextArea;
