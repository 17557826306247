import * as EmailValidator from 'email-validator';

export const required = value => {
  if (!value) return 'Required';
};

export const isEmail = value => {
  if (!EmailValidator.validate(value)) return 'Invalid email address';
};

export const alphanumeric = value => {
  if (!/^\w+$/.test(value)) {
    return `Only alphanumeric characters allowed.`;
  }
};

export const noDigits = value => {
  if (/\d/.test(value)) {
    return `Cannot contain numbers.`;
  }
};

export const minLength = (length = 0) => value => {
  if (value && value.length < length) {
    return `Too short.`;
  }
};

export const validateAll = (...functions) => value => {
  for (const func of functions) {
    let error = func(value);
    if (error) return error;
  }
};
